// @flow
/* eslint flowtype/require-parameter-type: 0 */
/* eslint flowtype/require-return-type: 0 */
import React, { useEffect, useState } from "react";
import type { Node } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Redirect, Switch } from "react-router-dom";
import type { History } from "@types/history";
import { withLDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { ThemeProvider } from "styled-components";
import loadSegment from "@delta-defense/frontend-analytics/loadSegment/loadSegment";
import setPage from "@delta-defense/frontend-analytics/setPage/setPage";
import setSegmentIdentity from "@delta-defense/frontend-analytics/setSegmentIdentity/setSegmentIdentity";
import leadTracked from "@delta-defense/frontend-analytics/leadTracked/leadTracked";
import userTracked from "@delta-defense/frontend-analytics/userTracked/userTracked";

import { courseLinkOverrides } from "shared/constants/courseLinkOverrides/courseLinkOverrides";
import { NONE, LOADING } from "shared/constants/status";
import getLaunchDarklyUser from "shared/utilities/getLaunchDarklyUser";
import { sharedRoutes, instructorRoutes, authedRoutes } from "./routes";
import { showToast } from "redux/modules/toasts";
import AsyncComponent from "shared/utilities/AsyncComponent";
import DefaultLayout from "shared/layout/DefaultLayout";
import { AppToaster } from "shared/components/AppToaster";

import type { OpenConfirmationModalParams } from "redux/modules/app/app";
import {
  openConfirmationModal,
  closeConfirmationModal,
} from "redux/modules/app/app";

import { getInstructorAgreements } from "redux/modules/instructorAgreements/instructorAgreementsActionCreators";

import type { State } from "Redux/modules/reducer";

const writeKey = process.env.SEGMENT_WRITE_KEY;
const launchDarklyId =
  process.env.LAUNCH_DARKLY_ID || "62cc5033305aa31221871713";
const launchDarklyUser = getLaunchDarklyUser();
const blankBaseRoutes = [
  "create-course",
  "edit-course",
  "submit-results",
  "sitemap",
];
const getIsBlank = (pathname: string): boolean => {
  const paths = pathname.split("/");
  const basepath = paths[1];
  const isBlank = blankBaseRoutes.indexOf(basepath) !== -1 ? true : false;
  return isBlank;
};

type ReduxProps = {
  userCallStatus: string,
  instructorNumber: string,
  toasts: {
    message: ?string,
    intent: ?string,
    timestamp: ?Date,
  },
  pathname: string,
  theme: ThemeState,
  name: string,
  surname: string,
  email: string,
  userId: ?string,
  memberSince: ?string,
  subscriptionExpiration: ?string,
  subscriptionLevel: ?string,
  subscriptionType: ?string,
  subscriptionStatus: string | null,
};

type Props = {
  history: History,
  openConfirmationModal: (params: OpenConfirmationModalParams) => mixed,
  closeConfirmationModal: () => mixed,
  showToast: (message: string, intent: string) => mixed,
  getInstructorAgreements: () => mixed,
} & ReduxProps;

const Router = ({
  userCallStatus,
  email,
  userId,
  history,
  instructorNumber,
  name,
  pathname,
  surname,
  theme,
  toasts: {
    timestamp: lastToastTs,
    message: toastMessage,
    intent: toastIntent,
  },
  closeConfirmationModal,
  openConfirmationModal,
  showToast,
  getInstructorAgreements,
  memberSince,
  subscriptionExpiration,
  subscriptionLevel,
  subscriptionType,
  subscriptionStatus,
}: Props): Node => {
  const ldClient = useLDClient();
  const isBlank = getIsBlank(pathname);
  const [loadedAnalytics, setLoadedAnalytics] = useState(false);

  useEffect(() => {
    if (lastToastTs) {
      AppToaster.show({ message: toastMessage, intent: toastIntent });
    }
  }, [lastToastTs]);

  let routes = [];

  // give access to all routes until me call is complete
  routes = routes.concat(sharedRoutes);
  if (
    !!instructorNumber ||
    userCallStatus === NONE ||
    userCallStatus === LOADING
  ) {
    routes = routes.concat(instructorRoutes);
  }
  if (!!userId) {
    routes = routes.concat(authedRoutes);
  }

  // Define me
  const me = {
    id: userId,
    name,
    surname,
    email,
    subscriptionStatus,
    subscriptionType,
    subscriptionLevel,
    subscriptionExpiration,
    memberSince,
  };

  // Datalayer
  useEffect(() => {
    import("@delta-defense/frontend-analytics/dataLayer/dataLayer");
    loadSegment(writeKey); // Load segment
    if (window.analytics) {
      // eslint-disable-next-line no-console
      console.log("🔬 Segment Analytics Loaded");
      setLoadedAnalytics(true);
    }
  }, []);

  // if Segment loaded & user logged in, identify user
  // otherwise Segment automatically assigns them an anonymousId
  useEffect(() => {
    if (!!loadedAnalytics && !!userId && !!name) {
      setSegmentIdentity(me, window.deltaAnalytics);
    }
  }, [loadedAnalytics, userId, name]);

  useEffect(() => {
    // Segment Tracking
    if (window.deltaAnalytics && !userId && !name) {
      leadTracked(window.deltaAnalytics); // track using dataLayer data
    }
    if (window.deltaAnalytics && userId && name) {
      userTracked(me, window.deltaAnalytics);
    }
    // Launch Darkly
    if (ldClient) {
      const user = {
        key: userId,
        email,
        firstName: name,
        lastName: surname,
      };
      ldClient.identify(user, null, () => undefined);
    }
  }, [pathname, userId, name]);

  useEffect(() => {
    const pageName =
      pathname.length === 0 ? "Training Homepage" : pathname.slice(1);
    if (window.deltaAnalytics) {
      setPage(pageName, window.deltaAnalytics);
    }
  }, [pathname]);

  // if instructor, check if accepted agreements
  useEffect(() => {
    if (!!instructorNumber) {
      getInstructorAgreements();
    }
  }, [instructorNumber]);

  // Temporary swoogo redirect
  useEffect(() => {
    const courseId = pathname.split("/")[3];
    const courseLinkOverride = courseLinkOverrides.find(
      (course) => course.courseId === courseId
    );
    const mappedUrl = courseLinkOverride?.overrideUrl;
    if (!!mappedUrl) {
      return (window.location.href = mappedUrl);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Route>
          <DefaultLayout isBlank={isBlank} isAuthed={!!userId}>
            <Switch>
              {routes.map(({ path, LazyComponent }) => (
                <Route
                  key={path}
                  exact
                  path={path}
                  render={(props) => (
                    <AsyncComponent {...props} LazyComponent={LazyComponent} />
                  )}
                />
              ))}
              {!!instructorNumber && (
                <Route key="connect" exact path="/connect">
                  <Redirect push to="/payments" />
                </Route>
              )}

              <Redirect
                from="*"
                to={!!instructorNumber ? "/my-classes" : "/not-found"}
              />
            </Switch>
          </DefaultLayout>
        </Route>
      </ConnectedRouter>
    </ThemeProvider>
  );
};

const mapStateToProps = ({
  auth: { userId },
  me: { status: userCallStatus, user },
  router: {
    location: { pathname },
  },
  theme,
  toasts,
}: {
  router: {
    location: {
      pathname: string,
    },
  },
} & State): ReduxProps => ({
  userCallStatus,
  email: user?.email,
  userId,
  instructorNumber: user?.instructor?.instructorNumber,
  name: user?.name,
  pathname,
  surname: user?.surname,
  theme,
  toasts,
  memberSince: user?.memberSince,
  subscriptionExpiration: user?.subscriptionExpiration,
  subscriptionLevel: user?.subscriptionLevel,
  subscriptionType: user?.subscriptionType,
  subscriptionStatus: user?.subscriptionStatus,
});

const actions = {
  openConfirmationModal,
  closeConfirmationModal,
  showToast,
  getInstructorAgreements,
};

export default withLDProvider({
  clientSideID: launchDarklyId,
  user: launchDarklyUser,
})(connect(mapStateToProps, actions)(Router));
