// @flow
import { Record } from "immutable";

export type InstructorRecordI = {
  averageReviewRating: number,
  email: string,
  firstName: string,
  guid: string,
  lastName: string,
  link: string,
  number: number,
  totalReviewCount: number,
};

const InstructorRecord = Record({
  averageReviewRating: 0,
  email: "",
  firstName: "",
  guid: "",
  lastName: "",
  link: "",
  number: 0,
  totalReviewCount: 0,
});

export default InstructorRecord;
