// @flow
import { Record } from "immutable";

export type CourseRecordI = {
  id: string,
  name: string,
  overrideUrl: string,
  link: string,
  editLink: string,
  manageLink: string,
  price: number,
  venue: string,
  futureOccurrencesCount: number,
  pastOccurrencesCount: number,
  isArchived: boolean,
  hasDates: boolean,
  canDelete: boolean,
  canArchive: boolean,
  isActionPending: boolean,
  isEligibleForInstructorAutomation: boolean,
  isInstructorCertifying: boolean,
  curriculumid: string,
};

const CourseRecord = Record({
  id: "",
  name: "",
  overrideUrl: "",
  link: "",
  editLink: "",
  manageLink: "",
  price: 0,
  venue: "",
  futureOccurrencesCount: 0,
  pastOccurrencesCount: 0,
  isArchived: false,
  hasDates: false,
  canDelete: false,
  canArchive: false,
  isActionPending: false,
  isEligibleForInstructorAutomation: false,
  isInstructorCertifying: false,
  curriculumId: "",
});

export default CourseRecord;
