// @flow
import * as slugify from "slugify";

const { URL_CHECKOUT = "https://checkout.training.usconcealedcarry.com" } =
  process.env;

const slugifyOptions = {
  replacement: "-", // replace spaces with replacement character, defaults to `-`
  remove: "", // remove characters that match regex, defaults to `undefined`
  lower: true, // convert to lower case, defaults to `false`
  strict: true, // strip special characters except replacement, defaults to `false`
  locale: "vi", // language code of the locale to use
  trim: true, // trim leading and trailing replacement chars, defaults to `true`
};

const cleanSlug = (name: string): string => {
  const slug = slugify(name, slugifyOptions);
  return slug;
};

export const createCourseUrl = (id: string, name: string): string => {
  const slug = cleanSlug(name);
  return `/class/${slug}/${id}`;
};

export const createBuyClassUrl = (
  courseId: string,
  courseName: string,
  occurrenceId: string,
  tID: string
): string => {
  const slug = cleanSlug(courseName);
  return `${URL_CHECKOUT}/register/${slug}/${courseId}?oid=${occurrenceId}${
    tID && tID !== "unknown" ? `?tid=${tID}` : ""
  }`;
};
