// @flow

export const GET_ME_QUERY = `
{
  me {
    id
    name
    surname
    email
    memberNumber
    subscriptionLevel
    instructor {
      id
      number
      instructorNumber
      isRangePartner
      hasPaymentProcessorId
    }
    memberSince
    subscriptionExpiration
    subscriptionType
    subscriptionStatus
    subscriptions{
      id
      type
      status
    }
  }
}
`;
