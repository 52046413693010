// @flow
import React from "react";
import type { Node } from "react";
import { useSelector } from "react-redux";

// $FlowIgnore
import PreRenderedDeltaNav from "raw-loader!../../../../node_modules/@delta-defense/global-navigation/elements/globalNav/delta-nav.html"; // eslint-disable-line
import "@delta-defense/global-navigation/elements/globalNav/styles.css";
import SubNavigation from "../SubNavigation/SubNavigation";
import { updateNavAuthedAttributes } from "./updateNavAuthedAttributes";
import type { State as MeState } from "Redux/modules/me/meReducer";
import type { State } from "Redux/modules/reducer";

const Header = (): Node => {
  const showSubmenu = useSelector(
    (state: State): boolean => state.app.showHeaderSubmenu
  );
  // $FlowIgnore
  const user = useSelector((state: State): MeState => state.me.user);

  React.useEffect(() => {
    import("@delta-defense/global-navigation/elements/globalNav/module");
  });

  React.useEffect(() => {
    if (!!user?.name) {
      updateNavAuthedAttributes(
        `${user.name} ${user.surname}`,
        user?.memberNumber,
        user?.subscriptionLevel && user.subscriptionLevel !== "null"
          ? user.subscriptionLevel
          : "free",
        !!user?.instructor?.number,
        user?.subscriptionType || "group-membership"
      );
    }
  }, [user]);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: PreRenderedDeltaNav }} />
      {showSubmenu && <SubNavigation isAuthed={!!user?.name} />}
    </>
  );
};

export default Header;
