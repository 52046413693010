// @flow

import { Record } from "immutable";

export type OccurrenceRecordInterface = {
  id: string,
  isPast: boolean,
  isResultsSubmitted: boolean,
  canEdit: boolean,
  canHaveRegistrants: boolean,
  canSubmitResults: boolean,
  canDelete: boolean,
  registrantsCount: number,
  capacity: number,
  registrantsLink: string,
  dateSubmitted: string,
  dateIds: Array<string>,
  instructorIds: Array<string>,
  isActionPending: boolean,
  promotionId: string,
};

const OccurrenceRecord = Record({
  id: "",
  isPast: false,
  isResultsSubmitted: false,
  canEdit: false,
  canHaveRegistrants: false,
  canSubmitResults: false,
  canDelete: false,
  registrantsCount: 0,
  capacity: 0,
  registrantsLink: "",
  dateSubmitted: "",
  dateIds: [],
  instructorIds: [],
  isActionPending: false,
  promotionId: "",
});

export default OccurrenceRecord;
