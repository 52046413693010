// @flow
import { Record } from "immutable";

export type RegistrationQuestionI = {
  question: string,
  shortCode: string,
  type: string,
  options: Array<string>,
};

export type OccurrenceRecordI = {
  id: string,
  parentType: string,
  parentId: string,
  name: string,
  capacity: number,
  canHaveRegistrants: boolean,
  canSubmitResults: boolean,
  isResultsSubmitted: boolean,
  dateSubmitted: string,
  startTime: string,
  endTime: string,
  dateIds: Array<string>,
  instructorIds: Array<string>,
  isEligibleForInstructorAutomation: boolean,
  webPayOnly: boolean,
  registrationQuestions: Array<RegistrationQuestionI>,
  isInstructorCertifying: boolean,
  willAssignPrerequisiteELearning: boolean,
};

const OccurrenceRecord = Record({
  id: "",
  parentType: "",
  parentId: "",
  name: "",
  capacity: 0,
  canHaveRegistrants: false,
  canSubmitResults: false,
  isResultsSubmitted: false,
  dateSubmitted: "",
  dateIds: [],
  startTime: "",
  endTime: "",
  instructorIds: [],
  isEligibleForInstructorAutomation: false,
  webPayOnly: false,
  registrationQuestions: [],
  isInstructorCertifying: false,
  willAssignPrerequisiteELearning: false,
});

export default OccurrenceRecord;
