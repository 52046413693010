// @flow
import { handleActions } from "redux-actions";

import type {
  apiErrorAction,
  apiErrorReturn,
} from "shared/constants/flowTypes";
import { ERROR, LOADING, LOADED, NONE } from "shared/constants/status";
import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_ERROR,
  ARCHIVE_REQUEST,
  ARCHIVE_SUCCESS,
  ARCHIVE_ERROR,
  CLEAR,
} from "./studentsActions";

import type {
  RequestArchiveStudentAction,
  ReceiveArchivedStudentAction,
  ArchiveStudentErrorAction,
  RequestStudentsAction,
  ReceiveStudentsAction,
} from "./studentsActionCreators";
// update list view when drawer changes
import { UPDATE_SUCCESS as UPDATE_STUDENT_SUCCESS } from "../studentDrawer/studentDrawerActions";
import type {
  ReceiveUpdatedStudentAction,
  Student,
} from "../studentDrawer/studentDrawerActionCreators";

// reducer
export type State = {
  submittedSearchValue: ?string,
  status: string,
  data: Array<Student>,
  total: number,
  count: number,
  perPage: number,
  currentPage: number,
  totalPages: number,
  errors: apiErrorReturn,
  errorMessage: string,
  pendingArchiveStudentIds: Array<string>,
};

export const initialState = {
  submittedSearchValue: null,
  status: NONE,
  data: [],
  total: 0,
  count: 0,
  perPage: 10,
  currentPage: 1,
  totalPages: 1,
  errors: [],
  errorMessage: "",
  pendingArchiveStudentIds: [],
};

const studentDrawerReducer = handleActions(
  {
    [FETCH_REQUEST]: (state: State, action: RequestStudentsAction): State => {
      const { page, perPage, search } = action.payload;

      return {
        ...state,
        submittedSearchValue: search,
        status: LOADING,
        data: [],
        currentPage: page,
        perPage,
      };
    },
    [FETCH_SUCCESS]: (state: State, action: ReceiveStudentsAction): State => {
      const {
        data,
        meta: { total, count, perPage, currentPage, totalPages },
      } = action.payload;

      return {
        ...state,
        status: LOADED,
        data,
        total,
        count,
        perPage,
        currentPage,
        totalPages,
      };
    },
    [FETCH_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [UPDATE_STUDENT_SUCCESS]: (
      state: State,
      action: ReceiveUpdatedStudentAction
    ): State => {
      const id = action.payload.data.id;
      const index = state.data.findIndex(
        (student: Student): boolean => student.id === id
      );

      let data = [...state.data];
      data[index] = action.payload.data;

      return {
        ...state,
        data,
      };
    },
    [ARCHIVE_REQUEST]: (
      state: State,
      action: RequestArchiveStudentAction
    ): State => {
      let pendingArchiveStudentIds = [...state.pendingArchiveStudentIds];
      pendingArchiveStudentIds.push(action.payload.id);

      return {
        ...state,
        pendingArchiveStudentIds,
      };
    },
    [ARCHIVE_SUCCESS]: (
      state: State,
      action: ReceiveArchivedStudentAction
    ): State => {
      const id = action.payload.data.id;

      // remove from list
      const dataIndex = state.data.findIndex(
        (student: Student): boolean => student.id === id
      );
      let data = [...state.data];
      data.splice(dataIndex, 1);

      // remove pending ids
      const pendingIndex = state.pendingArchiveStudentIds.findIndex(
        (studentId: string): boolean => studentId === id
      );
      let pendingArchiveStudentIds = [...state.pendingArchiveStudentIds];
      pendingArchiveStudentIds.splice(pendingIndex, 1);

      return {
        ...state,
        data,
        total: state.total - 1,
        count: state.count - 1,
      };
    },
    [ARCHIVE_ERROR]: (
      state: State,
      action: ArchiveStudentErrorAction
    ): State => {
      const id = action.error.actionParams.id;
      const index = state.pendingArchiveStudentIds.findIndex(
        (studentId: string): boolean => studentId === id
      );
      let pendingArchiveStudentIds = [...state.pendingArchiveStudentIds];
      pendingArchiveStudentIds.splice(index, 1);

      return {
        ...state,
        pendingArchiveStudentIds,
      };
    },
    [CLEAR]: (state: State): State => ({
      ...initialState,
    }),
  },
  initialState
);

export default studentDrawerReducer;
