// @flow
import { APP_NAMESPACE } from "shared/constants/application";

export const namespace: string = `${APP_NAMESPACE}/students`;

// Actions
export const FETCH_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const FETCH_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const FETCH_ERROR: string = `${namespace}/FETCH/ERROR`;

export const ARCHIVE_REQUEST: string = `${namespace}/ARCHIVE/REQUEST`;
export const ARCHIVE_SUCCESS: string = `${namespace}/ARCHIVE/SUCCESS`;
export const ARCHIVE_ERROR: string = `${namespace}/ARCHIVE/ERROR`;

export const CLEAR: string = `${namespace}/CLEAR`;
